import { useTranslation } from '@pancakeswap/localization'
import { ChainId } from '@pancakeswap/sdk'
import {
  // Box,
  Flex,
  InjectedModalProps,
  Link,
  Modal,
  ExpertModal,
  // PancakeToggle,
  QuestionHelper,
  Text,
  // ThemeSwitcher,
  Toggle,
} from '@pancakeswap/uikit'
// import { SUPPORT_ZAP } from 'config/constants/supportChains'
import { useActiveChainId } from 'hooks/useActiveChainId'
// import useTheme from 'hooks/useTheme'
import { useCallback, useState } from 'react'
import { useSwapActionHandlers } from 'state/swap/useSwapActionHandlers'
import {
  useAudioModeManager,
  useExpertModeManager,
  // useSubgraphHealthIndicatorManager,
  useUserExpertModeAcknowledgementShow,
  useUserSingleHopOnly,
  // useUserUsernameVisibility,
  // useZapModeManager,
} from 'state/user/hooks'
import { useUserTokenRisk } from 'state/user/hooks/useUserTokenRisk'
import { useUserTokenTax } from 'state/user/hooks/useUserTokenTax'
// import { useStableSwapByDefault } from 'state/user/smartRouter'
// import { useMMLinkedPoolByDefault } from 'state/user/mmLinkedPool'
import styled from 'styled-components'
import GasSettings from './GasSettings'
import TransactionSettings from './TransactionSettings'
import { SettingsMode } from './types'

const ScrollableContainer = styled(Flex)`
  flex-direction: column;
  height: auto;
  ${({ theme }) => theme.mediaQueries.xs} {
    max-height: 90vh;
  }
  ${({ theme }) => theme.mediaQueries.md} {
    max-height: none;
  }
`

export const withCustomOnDismiss =
  (Component) =>
  ({
    onDismiss,
    customOnDismiss,
    mode,
    ...props
  }: {
    onDismiss?: () => void
    customOnDismiss: () => void
    mode: SettingsMode
  }) => {
    const handleDismiss = useCallback(() => {
      onDismiss?.()
      if (customOnDismiss) {
        customOnDismiss()
      }
    }, [customOnDismiss, onDismiss])

    return <Component {...props} mode={mode} onDismiss={handleDismiss} />
  }

const SettingsModal: React.FC<React.PropsWithChildren<InjectedModalProps>> = ({ onDismiss, mode }) => {
  const [showConfirmExpertModal, setShowConfirmExpertModal] = useState(false)
  const [showExpertModeAcknowledgement, setShowExpertModeAcknowledgement] = useUserExpertModeAcknowledgementShow()
  const [expertMode, toggleExpertMode] = useExpertModeManager()
  const [singleHopOnly, setSingleHopOnly] = useUserSingleHopOnly()
  const [audioPlay, toggleSetAudioMode] = useAudioModeManager()
  // const [zapMode, toggleZapMode] = useZapModeManager()
  // const [subgraphHealth, setSubgraphHealth] = useSubgraphHealthIndicatorManager()
  // const [userUsernameVisibility, setUserUsernameVisibility] = useUserUsernameVisibility()
  const { onChangeRecipient } = useSwapActionHandlers()
  const { chainId } = useActiveChainId()
  // const [isStableSwapByDefault, setIsStableSwapByDefault] = useStableSwapByDefault()
  // const [isMMLinkedPoolByDefault, setIsMMLinkedPoolByDefault] = useMMLinkedPoolByDefault()
  const [tokenRisk, setTokenRisk] = useUserTokenRisk()
  const [tokenTax, setTokenTax] = useUserTokenTax()

  const { t } = useTranslation()
  // const { isDark, setTheme } = useTheme()

  if (showConfirmExpertModal) {
    return (
      <ExpertModal
        setShowConfirmExpertModal={setShowConfirmExpertModal}
        onDismiss={onDismiss}
        toggleExpertMode={toggleExpertMode}
        setShowExpertModeAcknowledgement={setShowExpertModeAcknowledgement}
      />
    )
  }

  const handleExpertModeToggle = () => {
    if (expertMode || !showExpertModeAcknowledgement) {
      onChangeRecipient(null)
      toggleExpertMode()
    } else {
      setShowConfirmExpertModal(true)
    }
  }


  return (
    <Modal title={t('Settings')} headerBackground="gradientCardHeader" onDismiss={onDismiss}>
      <ScrollableContainer>
        {/* {mode === SettingsMode.GLOBAL && ( */}
        {/*   <> */}
            {/* <Flex pb="24px" flexDirection="column"> */}
            {/*   <Text bold textTransform="uppercase" fontSize="18px" color="secondary" mb="24px"> */}
            {/*     {t('Global')} */}
            {/*   </Text> */}
            {/*   <Flex justifyContent="space-between" mb="24px"> */}
            {/*     <Text>{t('Dark mode')}</Text> */}
            {/*     <ThemeSwitcher isDark={isDark} toggleTheme={() => setTheme(isDark ? 'light' : 'dark')} /> */}
            {/*   </Flex> */}
              {/* <Flex justifyContent="space-between" alignItems="center" mb="24px"> */}
              {/*   <Flex alignItems="center"> */}
              {/*     <Text>{t('Subgraph Health Indicator')}</Text> */}
              {/*     <QuestionHelper */}
              {/*       text={t( */}
              {/*         'Turn on subgraph health indicator all the time. Default is to show the indicator only when the network is delayed', */}
              {/*       )} */}
              {/*       placement="top" */}
              {/*       ml="4px" */}
              {/*     /> */}
              {/*   </Flex> */}
              {/*   <Toggle */}
              {/*     id="toggle-subgraph-health-button" */}
              {/*     checked={subgraphHealth} */}
              {/*     scale="md" */}
              {/*     onChange={() => { */}
              {/*       setSubgraphHealth(!subgraphHealth) */}
              {/*     }} */}
              {/*   /> */}
              {/* </Flex> */}
              {/* <Flex justifyContent="space-between" alignItems="center" mb="24px"> */}
              {/*   <Flex alignItems="center"> */}
              {/*     <Text>{t('Show username')}</Text> */}
              {/*     <QuestionHelper text={t('Shows username of wallet instead of bunnies')} placement="top" ml="4px" /> */}
              {/*   </Flex> */}
              {/*   <Toggle */}
              {/*     id="toggle-username-visibility" */}
              {/*     checked={userUsernameVisibility} */}
              {/*     scale="md" */}
              {/*     onChange={() => { */}
              {/*       setUserUsernameVisibility(!userUsernameVisibility) */}
              {/*     }} */}
              {/*   /> */}
              {/* </Flex> */}
              {/* {chainId === ChainId.BSC && ( */}
              {/*   <> */}
                  {/* <Flex justifyContent="space-between" alignItems="center" mb="24px"> */}
                  {/*   <Flex alignItems="center"> */}
                  {/*     <Text>{t('Token Risk Scanning')}</Text> */}
                  {/*     <QuestionHelper */}
                  {/*       text={ */}
                  {/*         <> */}
                  {/*           <Text>{t('Automatic risk scanning for the selected token')}</Text> */}
                  {/*           <Text as="span">{t('Risk scan results are provided by a third party')}</Text> */}
                  {/*           <Link style={{ display: 'inline' }} ml="4px" external href="https://www.avengerdao.org"> */}
                  {/*             AvengerDAO */}
                  {/*           </Link> */}
                  {/*           <Text my="8px"> */}
                  {/*             {t( */}
                  {/*               'It is a tool for indicative purposes only to allow users to check the reference risk level of a BNB Chain Smart Contract. Please do your own research - interactions with any BNB Chain Smart Contract is at your own risk.', */}
                  {/*             )} */}
                  {/*           </Text> */}
                  {/*         </> */}
                  {/*       } */}
                  {/*       placement="top" */}
                  {/*       ml="4px" */}
                  {/*     /> */}
                  {/*   </Flex> */}
                  {/*   <Toggle */}
                  {/*     id="toggle-username-visibility" */}
                  {/*     checked={tokenRisk} */}
                  {/*     scale="md" */}
                  {/*     onChange={() => { */}
                  {/*       setTokenRisk(!tokenRisk) */}
                  {/*     }} */}
                  {/*   /> */}
                  {/* </Flex> */}
              {/*     <GasSettings /> */}
              {/*   </> */}
              {/* )} */}
        {/*     </Flex> */}
        {/*   </> */}
        {/* )} */}
        {/* {mode === SettingsMode.SWAP_LIQUIDITY && ( */}
          <>
            <Flex pt="3px" flexDirection="column">
              {/* <Text bold textTransform="uppercase" fontSize="18px" color="secondary" mb="24px"> */}
              {/*   {t('Swaps & Liquidity')} */}
              {/* </Text> */}
              <Flex justifyContent="space-between" alignItems="center" mb="24px">
                {chainId === ChainId.BSC && <GasSettings />}
              </Flex>
              <TransactionSettings />
            </Flex>

             <Flex justifyContent="space-between" alignItems="center" mb="24px">
               <Flex alignItems="center">
                 <Text>{t('Token Risk Scanning')}</Text>
                 <QuestionHelper
                   text={
                     <>
                       <Text>{t('Automatic risk scanning for the selected token')}</Text>
                       <Text as="span">{t('Risk scan results are provided by a third party')}</Text>
                       <Link style={{ display: 'inline' }} ml="2px" external href="https://gopluslabs.io">GOPLUS</Link>
                     </>
                   }
                   placement="top"
                   ml="4px"
                 />
               </Flex>
               <Toggle
                 id="toggle-tokenRisk-visibility"
                 checked={tokenRisk}
                 scale="md"
                 onChange={()=>setTokenRisk(!tokenRisk)}
               />
             </Flex>

             <Flex justifyContent="space-between" alignItems="center" mb="24px">
               <Flex alignItems="center">
                 <Text>{t('Slippage Tolerance Detection')}</Text>
                 <QuestionHelper
                   text={
                     <>
                       <Text>{t('Tax is simulated and may differ from actual transactions.')}</Text>
                       <Text>{t('To ensure data accuracy, the detector performed multiple checks to minimize false results.')}</Text>
                     </>
                   }
                   placement="top"
                   ml="4px"
                 />
               </Flex>
               <Toggle
                 id="toggle-tokenRisk-visibility"
                 checked={tokenTax}
                 scale="md"
                 onChange={()=>setTokenTax(!tokenTax)}
               />
             </Flex>


            {/* {SUPPORT_ZAP.includes(chainId) && ( */}
            {/*   <Flex justifyContent="space-between" alignItems="center" mb="24px"> */}
            {/*     <Flex alignItems="center"> */}
            {/*       <Text>{t('Zap (Beta)')}</Text> */}
            {/*       <QuestionHelper */}
            {/*         text={ */}
            {/*           <Box> */}
            {/*             <Text> */}
            {/*               {t( */}
            {/*                 'Zap enables simple liquidity provision. Add liquidity with one token and one click, without manual swapping or token balancing.', */}
            {/*               )} */}
            {/*             </Text> */}
            {/*             <Text> */}
            {/*               {t( */}
            {/*                 'If you experience any issue when adding or removing liquidity, please disable Zap and retry.', */}
            {/*               )} */}
            {/*             </Text> */}
            {/*           </Box> */}
            {/*         } */}
            {/*         placement="top" */}
            {/*         ml="4px" */}
            {/*       /> */}
            {/*     </Flex> */}
            {/*     <Toggle */}
            {/*       checked={zapMode} */}
            {/*       scale="md" */}
            {/*       onChange={() => { */}
            {/*         toggleZapMode(!zapMode) */}
            {/*       }} */}
            {/*     /> */}
            {/*   </Flex> */}
            {/* )} */}
            <Flex justifyContent="space-between" alignItems="center" mb="24px">
              <Flex alignItems="center">
                <Text>{t('Expert Mode')}</Text>
                <QuestionHelper
                  text={t('Bypasses confirmation modals and allows high slippage trades. Use at your own risk.')}
                  placement="top"
                  ml="4px"
                />
              </Flex>
              <Toggle
                id="toggle-expert-mode-button"
                scale="md"
                checked={expertMode}
                onChange={handleExpertModeToggle}
              />
            </Flex>
            <Flex justifyContent="space-between" alignItems="center" mb="24px">
              <Flex alignItems="center">
                <Text>{t('Disable Multihops')}</Text>
                <QuestionHelper text={t('Restricts swaps to direct pairs only.')} placement="top" ml="4px" />
              </Flex>
              <Toggle
                id="toggle-disable-multihop-button"
                checked={singleHopOnly}
                scale="md"
                onChange={() => {
                  setSingleHopOnly(!singleHopOnly)
                }}
              />
            </Flex>
            <Flex justifyContent="space-between" alignItems="center" mb="24px">
              <Flex alignItems="center">
                <Text>{t('Flippy sounds')}</Text>
                <QuestionHelper
                  text={t('Fun sounds to make a truly immersive pancake-flipping trading experience')}
                  placement="top"
                  ml="4px"
                />
              </Flex>
              <Toggle
                id="toggle-audioPlay-visibility"
                checked={audioPlay}
                scale="md"
                onChange={toggleSetAudioMode}
              />
            </Flex>
            {/* <Flex justifyContent="space-between" alignItems="center" mb="24px"> */}
            {/*   <Flex alignItems="center"> */}
            {/*     <Text>{t('MM Linked Pool')}</Text> */}
            {/*     <QuestionHelper */}
            {/*       text={t('Trade through the market makers if they provide better deal')} */}
            {/*       placement="top" */}
            {/*       ml="4px" */}
            {/*     /> */}
            {/*   </Flex> */}
            {/*   <Toggle */}
            {/*     id="toggle-disable-mm-button" */}
            {/*     checked={isMMLinkedPoolByDefault} */}
            {/*     onChange={(e) => setIsMMLinkedPoolByDefault(e.target.checked)} */}
            {/*     scale="md" */}
            {/*   /> */}
            {/* </Flex> */}
            {/* <Flex justifyContent="space-between" alignItems="center" mb="24px"> */}
            {/*   <Flex alignItems="center"> */}
            {/*     <Text>{t('Use StableSwap by default')}</Text> */}
            {/*     <QuestionHelper */}
            {/*       text={ */}
            {/*         <Flex> */}
            {/*           <Text mr="5px"> */}
            {/*             {t( */}
            {/*               'Stableswap will enable users to save fees on trades. Output cannot be edited for routes that include StableSwap', */}
            {/*             )} */}
            {/*           </Text> */}
            {/*         </Flex> */}
            {/*       } */}
            {/*       placement="top" */}
            {/*       ml="4px" */}
            {/*     /> */}
            {/*   </Flex> */}
            {/*   <Toggle */}
            {/*     id="toggle-disable-smartRouter-button" */}
            {/*     checked={isStableSwapByDefault} */}
            {/*     onChange={(e) => setIsStableSwapByDefault(e.target.checked)} */}
            {/*     scale="md" */}
            {/*   /> */}
            {/* </Flex> */}
          </>
        {/* )} */}
      </ScrollableContainer>
    </Modal>
  )
}

export default SettingsModal
