import {useAtom} from 'jotai'
import atomWithStorageWithErrorCatch from 'utils/atomWithStorageWithErrorCatch'

const userTokenTaxAtom = atomWithStorageWithErrorCatch<boolean>('swap:user-token-tax', true)
const userTokenTaxAutoAtom = atomWithStorageWithErrorCatch<boolean>('swap:user-token-tax-auto', true)

export function useUserTokenTax() {
  return useAtom(userTokenTaxAtom)
}

export function useUserTokenTaxAuto() {
  return useAtom(userTokenTaxAutoAtom)
}

const userTokenTaxA = atomWithStorageWithErrorCatch<string>('swap:user-token-tax-a', '', () => window.sessionStorage)
const userTokenTaxB = atomWithStorageWithErrorCatch<string>('swap:user-token-tax-b', '', () => window.sessionStorage)

export function useUserTokenTaxA() {
  return useAtom(userTokenTaxA)
}

export function useUserTokenTaxB() {
  return useAtom(userTokenTaxB)
}